import { Link } from "gatsby"
import React from "react"
import Logo from "./logo-large"

const DesktopMenu = () => (
  <nav className="menu" data-sal="fade" data-sal-duration="1000">
    <Link to="/">
      <Logo className="logo" />
    </Link>
    <Link to="/music/" activeClassName="active" partiallyActive={true}>
      Music
    </Link>
    <Link to="/video/" activeClassName="active" partiallyActive={true}>
      Video
    </Link>
    <Link to="/projects/" activeClassName="active" partiallyActive={true}>
      Projects
    </Link>

    <Link to="/live/" activeClassName="active" partiallyActive={true}>
      Live
    </Link>
    <Link to="/about/" activeClassName="active" partiallyActive={true}>
      About
    </Link>
    <Link to="/contact/" activeClassName="active" partiallyActive={true}>
      Contact
    </Link>
  </nav>
)

export default DesktopMenu
