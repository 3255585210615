import React from "react"
import Transition from "../components/transition"
import DesktopMenu from "../components/desktop-menu"

const TemplateWrapper = ({ children, location }) => (
  <>
    <header>
      <DesktopMenu />
    </header>
    <Transition location={location}>{children}</Transition>
  </>
)

export default TemplateWrapper
